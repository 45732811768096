<template>
  <div>
    <div class="header">
      <div>
        <img src="@/assets/img/logo.png" />
      </div>
      <div
        class="iconfont icongengduo"
        style="font-size: 24px"
        @click="showPopup"
      ></div>
    </div>
    <div>
      <van-popup v-model="show" position="right" :style="{ height: '100%' }">
        <div class="popupBox">
          <div
            class="tabItem"
            :style="`${
              item.name == '工业产品'
                ? 'color: #999999;'
                : item.name == '冰眸'
                ? 'color: #999999;'
                : ''
            }`"
            v-for="(item, index) in tabList"
            :key="index"
            @click="
              item.name == '冰眸'
                ? goLink('http://bmou.cn/')
                : item.name == '微林产品'
                ? ''
                : handleSwitchChange(index, item.path)
            "
          >
            {{ item.name }}
          </div>
          <div
            class="tabItem_active"
            :style="`top: ${activeID * 54}px;`"
            @click="
              handleSwitchChange(
                activeID,
                activeID == 0
                  ? '/HomeMobile'
                  : activeID == 2
                  ? '/productMobile'
                  : activeID == 4
                  ? '/newsMobile'
                  : activeID == 5
                  ? '/aboutMobile'
                  : activeID == 6
                  ? '/contactMobile'
                  : ''
              )
            "
          >
            
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      tabList: [
        {
          name: "首页",
          path: "/HomeMobile",
        },
        {
          name: "微林产品",
          path: "",
        },
        {
          name: "工业产品",
          path: "/productMobile",
        },
        {
          name: "冰眸",
          path: "",
        },
        {
          name: "公司资讯",
          path: "/newsMobile",
        },
        {
          name: "关于我们",
          path: "/aboutMobile",
        },
        {
          name: "联系我们",
          path: "/contactMobile",
        },
      ],
      activeID: 0,
    };
  },
  mounted() {
    this.handleInit();
  },
  watch: {
    $route() {
      this.handleInit();
    },
  },
  methods: {
    // 初始化路由
    handleInit() {
      let path = this.$route.path;
      if (path == "/HomeMobile") {
        this.activeID = 0;
      } else if (path == "/productMobile") {
        this.activeID = 2;
      } else if (path == "/newsMobile") {
        this.activeID = 4;
      } else if (path == "/aboutMobile") {
        this.activeID = 5;
      } else if (path == "/contactMobile") {
        this.activeID = 6;
      }
    },
    // 跳转外网
    goLink(url) {
      window.open(url, "_blank");
    },
    // 展开侧边栏
    showPopup() {
      this.show = true;
    },
    // 处理选中问题
    handleSwitchChange(index, path) {
      this.activeID = index;
      this.$router.push({
        path: path,
      });
      this.show = false;
    },
  },
};
</script>

<style scoped>
.header {
  z-index: 999;
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 44px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
.header img {
  height: 44px;
  width: 100px;
  display: flex;
  object-fit: cover;
}
.popupBox {
  position: relative;
  margin: 20px 0;
}
.tabItem {
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  padding: 0 28px;
  font-size: 16px;
}
.tabItem_active {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  color: #ffffff00;
  padding: 0 28px;
  height: 54px;
  background-color: #00268010;
  border-left: 4px solid #002780;
  transition: all 0.3s;
}
</style>