import Vue from 'vue'
import VueRouter from 'vue-router'
import { discern } from '@/utils/discern.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: "首页",
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      title: "关于我们",
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/Product.vue'),
    meta: {
      title: "工业产品",
    }
  },
  {
    path: '/productDetails',
    name: 'ProductDetails',
    component: () => import('@/views/ProductDetails.vue'),
    meta: {
      title: "工业产品详情",
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News.vue'),
    meta: {
      title: "新闻资讯",
    }
  },
  {
    path: '/newsDetails',
    name: 'NewsDetails',
    component: () => import('@/views/NewsDetails.vue'),
    meta: {
      title: "新闻详情",
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      title: "联系我们",
    }
  },
  // 移动端路由
  {
    path: '/HomeMobile',
    name: 'HomeMobile',
    component: () => import('@/viewMobile/HomeMobile.vue'),
    meta: {
      title: "移动端首页",
      type: "mobile"
    }
  },
  {
    path: '/productMobile',
    name: 'ProductMobile',
    component: () => import('@/viewMobile/ProductMobile.vue'),
    meta: {
      title: "工业产品",
      type: "mobile"
    }
  },
  {
    path: '/newsMobile',
    name: 'NewsMobile',
    component: () => import('@/viewMobile/NewsMobile.vue'),
    meta: {
      title: "新闻资讯",
      type: "mobile"
    }
  },
  {
    path: '/newsDetailsMobile',
    name: 'NewsDetailsMobile',
    component: () => import('@/viewMobile/NewsDetailsMobile.vue'),
    meta: {
      title: "资讯详情",
      type: "mobile"
    }
  },
  {
    path: '/aboutMobile',
    name: 'AboutMobile',
    component: () => import('@/viewMobile/AboutMobile.vue'),
    meta: {
      title: "关于我们",
      type: "mobile"
    }
  },
  {
    path: '/contactMobile',
    name: 'ContactMobile',
    component: () => import('@/viewMobile/ContactMobile.vue'),
    meta: {
      title: "联系我们",
      type: "mobile"
    }
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (!discern()) {
    if (to.path != "/HomeMobile" && to.meta.type != "mobile") {
      next("/HomeMobile");
    }
  }
  next();
});

export default router