<template>
  <div class="goTop" @click="goTop()">
    <i class="el-icon-arrow-up"></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    goTop() {
      let element = document.getElementById("app");
      element.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}
</script>

<style scope>
.goTop {
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #0000006e;
}
.goTop i {
  color: #fff;
  font-size: 28px;
}
</style>