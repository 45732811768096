<template>
  <div class="topClass">
    <!-- 公司icon -->
    <div class="topIconClass">
      <div>
        <img src="@/assets/img/logo.png" />
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="topNavClass">
      <div>
        <div :style="activeIndex == 0 ? 'font-weight: 600;' : ''" @click="handleSelected(0)">首页</div>
      </div>
      <div>
        <el-dropdown trigger="click" placement="bottom">
          <div :style="activeIndex == 1 ? 'font-weight: 600;' : ''" @click="handleSelected(1)" class="topDropdown">微林产品</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div class="topDropdownMenu" @click="goRouter('/product')">
                工业产品
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div class="topDropdownMenu" @click="openWin()">冰眸</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div>
        <div :style="activeIndex == 2 ? 'font-weight: 600;' : ''" @click="handleSelected(2)">公司资讯</div>
      </div>
      <div>
        <div :style="activeIndex == 3 ? 'font-weight: 600;' : ''" @click="handleSelected(3)">关于我们</div>
      </div>
      <div>
        <div :style="activeIndex == 4 ? 'font-weight: 600;' : ''" @click="handleSelected(4)">联系我们</div>
      </div>
      <!-- 选中状态 -->
      <div
        :style="`width: 36px;height: 6px; left: calc(120px * ${activeIndex} + (120px * 0.5) - (36px * 0.5));`"
        class="selectedState"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
    };
  },
  watch:{
    $route() {
      this.handleInit();
    },
  },
  methods: {
    // 初始化路由
    handleInit() {
      let path = this.$route.path;
      if (path == "/") {
        this.activeIndex = 0;
      } else if (path == "/product" || path == "/productDetails") {
        this.activeIndex = 1;
      } else if (path == "/news" || path == "/newsDetails") {
        this.activeIndex = 2;
      } else if (path == "/about") {
        this.activeIndex = 3;
      } else if (path == "/contact") {
        this.activeIndex = 4;
      }
    },
    // 处理选中
    handleSelected(index) {
      this.activeIndex = index;
      if (index == 0) {
        this.goRouter("/");
      } else if (index == 2) {
        this.goRouter("/news");
      } else if (index == 3) {
        this.goRouter("/about");
      } else if (index == 4) {
        this.goRouter("/contact");
      }
    },
    // 打开新标签页
    openWin() {
      window.open("http://bmou.cn/", "_blank");
    },
    // 跳转路由
    goRouter(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
.topClass {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  z-index: 99999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e3e3;
}
.topClass > div {
  width: 100%;
}
/* 导航栏图标 */
.topIconClass {
  display: flex;
  align-items: center;
  justify-content: center;
}
.topIconClass img {
  display: flex;
  object-fit: cover;
}
/* 导航栏 */
.topNavClass {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.topNavClass > div {
  width: 120px;
  height: 88px;
  font-size: 20px;
  color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topNavClass > div > div {
  cursor: pointer;
}
.topDropdown {
  font-size: 20px;
  color: #1a1a1a;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topDropdownMenu {
  box-sizing: border-box;
  width: 80px;
  height: 69px;
  font-size: 20px;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 选中状态 */
.selectedState {
  position: absolute;
  bottom: 5px;
  border-radius: 6px;
  background: #000080;
  transition: left 0.5s;
}
</style>